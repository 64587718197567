import React, { useEffect } from 'react';
import { i18nWhenReady } from '../i18n';

enum InitializationStatus {
  Failure = 0,
  Success = 1,
}

interface PropsType {
  children: JSX.Element;
}

function AppInitWrapper(props: PropsType) {
  useEffect(() => {
    // Our application is initialized, with the possible exception of i18n
    // Use this callback to log our final initialization time and status
    i18nWhenReady((err: any) => {
      console.log('Error i18n', err);
    });
  }, []);

  return props.children;
}

export default AppInitWrapper;
