import { Stage, IDP } from 'src/constants/constants';
import { getStage } from 'src/utils/utils';

type AuthConfig = {
  [key in IDP]: {
    hostedUIDomain: string;
    userPoolId: string;
    userPoolWebClientId: string;
    region: string;
    providerName: string;
    identityPoolId: string;
  };
};

type APICONFIG = {
  [key in Stage]: {
    aesBEURL: string;
    aesBERegion: string;
    authConfig: Partial<AuthConfig>;
  };
};

export const API_CONFIG: APICONFIG = {
  [Stage.DEV]: {
    aesBEURL: 'https://us-west-2.beta.api.alexaservices.com/',
    aesBERegion: 'us-west-2',
    authConfig: {
      [IDP.amazonFederate]: {
        hostedUIDomain: 'alexa-aes-midway-beta.auth.us-west-2.amazoncognito.com',
        userPoolId: 'us-west-2_aHwikCLQ0',
        userPoolWebClientId: '74nguoshd3vmugkj0obn2ncaf1',
        region: 'us-west-2',
        providerName: 'AmazonFederate',
        identityPoolId: 'us-west-2:6acf1648-21c6-4074-88c2-2a0081bc1cfe',
      },
    },
  },
  [Stage.BETA]: {
    aesBEURL: 'https://us-west-2.beta.api.alexaservices.com/',
    aesBERegion: 'us-west-2',
    authConfig: {
      // See https://quip-amazon.com/l2UfAQiJe3wj/AES-SSO-Cognito-Integration
      [IDP.amazonFederate]: {
        hostedUIDomain: 'alexa-aes-midway-beta.auth.us-west-2.amazoncognito.com',
        userPoolId: 'us-west-2_aHwikCLQ0',
        userPoolWebClientId: '74nguoshd3vmugkj0obn2ncaf1',
        region: 'us-west-2',
        providerName: 'AmazonFederate',
        identityPoolId: 'us-west-2:6acf1648-21c6-4074-88c2-2a0081bc1cfe',
      },
    },
  },
  [Stage.GAMMA]: {
    aesBEURL: 'https://us-east-1.gamma.api.alexaservices.com/',
    aesBERegion: 'us-east-1',
    authConfig: {
      [IDP.amazonFederate]: {
        hostedUIDomain: 'alexa-aes-midway-gamma.auth.us-east-1.amazoncognito.com',
        userPoolId: 'us-east-1_HWeZdf6mG',
        userPoolWebClientId: '29ln22i4274i8kdl8t9g8lv6ro',
        region: 'us-east-1',
        providerName: 'AmazonFederate',
        identityPoolId: 'us-east-1:f8d661fd-988e-40f4-b2d8-1ab6af08b62f',
      },
    },
  },
  [Stage.PROD]: {
    aesBEURL: 'https://api.alexaservices.com/',
    aesBERegion: 'us-east-1',
    authConfig: {
      [IDP.amazonFederate]: {
        hostedUIDomain: 'midway.auth.us-east-1.amazoncognito.com',
        userPoolId: 'us-east-1_X7cUknfwG',
        userPoolWebClientId: '1iludafqvcv2nh28s3kkgtfng3',
        region: 'us-east-1',
        providerName: 'AmazonFederate',
        identityPoolId: 'us-east-1:a4bdc217-bded-4487-80d0-d5ba99863e02',
      },
      [IDP.disneyMyID]: {
        hostedUIDomain: 'disney.auth.us-east-1.amazoncognito.com',
        userPoolId: 'us-east-1_qUwf1FqMy',
        userPoolWebClientId: '19si87ffc9lun28vgq5tv7f6li',
        region: 'us-east-1',
        providerName: 'DisneyMyID',
        identityPoolId: 'us-east-1:a4bdc217-bded-4487-80d0-d5ba99863e02',
      },
    },
  },
};

export function getApiConfig() {
  const stage = getStage();

  return API_CONFIG[stage];
}
