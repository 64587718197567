export enum Stage {
  DEV = 'dev',
  BETA = 'beta',
  GAMMA = 'gamma',
  PROD = 'prod',
}

export enum IDP {
  amazonFederate = 'amazonFederate',
  disneyMyID = 'disneyMyID',
}

export enum AMPLIFY_API_NAME {
  AES_BE = 'AES_BE',
}
